<template>
  <div class="wrapper flex align-start justify-between">
    <div class="flex-sub">
      <el-form :model="salaryForm"
               label-width="160px"
               :rules="rules" ref="salaryForm" class="add-form">

        <el-form-item label="姓名" prop="xingming">
          <el-input v-model="salaryForm.xingming" placeholder="请填写姓名" disabled></el-input>
        </el-form-item>

        <el-form-item label="入职日期" prop="ruzhiriqi">
          <el-date-picker align="center" disabled
                          class="w-all"
                          v-model="salaryForm.ruzhiriqi"
                          type="date"
                          placeholder="请选择入职日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="离职日期" prop="lizhiriqi">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="salaryForm.lizhiriqi"
                          type="date"
                          placeholder="请选择离职日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="当月应出勤" prop="dangyueyingchuqin">
          <el-input v-model="salaryForm.dangyueyingchuqin" placeholder="请填写当月应出勤" type="number">
            <template slot="append">天</template>
          </el-input>
        </el-form-item>
        <el-form-item label="当月实际出勤" prop="dangyueshiichuqin">
          <el-input v-model="salaryForm.dangyueshiichuqin" placeholder="请填写当月实际出勤" type="number">
            <template slot="append">天</template>
          </el-input>
        </el-form-item>
        <el-form-item label="基本工资" prop="jibengongzi">
          <el-input v-model="salaryForm.jibengongzi" placeholder="请填写基本工资" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="考勤相关扣款" prop="kaoqinkoukuan">
          <el-input v-model="salaryForm.kaoqinkoukuan" placeholder="请填写考勤相关扣款（年假、事假、病假、旷工等）" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="离职当月应发工资" prop="lizhiyingfa">
          <el-input v-model="salaryForm.lizhiyingfa" placeholder="请填写离职当月应发工资" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="年假" prop="weiyongnianjiahetiaoxiu">
          <el-input v-model="salaryForm.weiyongnianjiahetiaoxiu" placeholder="请填写年假" type="number">
            <template slot="append">天</template>
          </el-input>
        </el-form-item>
        <el-form-item label="带薪病假" prop="sickLeaveDays">
          <el-input v-model="salaryForm.sickLeaveDays" placeholder="请填写带薪病假" type="number">
            <template slot="append">天</template>
          </el-input>
        </el-form-item>
        <el-form-item label="其他" prop="xinzi_qita">
          <el-input v-model="salaryForm.xinzi_qita" placeholder="请填写其他"></el-input>
        </el-form-item>
        <el-form-item label="社保公积金代扣代缴" prop="gongjijindaikou">
          <el-input v-model="salaryForm.gongjijindaikou" placeholder="请填写社保公积金代扣代缴" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="个税代扣代缴" prop="geshuidaikou">
          <el-input v-model="salaryForm.geshuidaikou" placeholder="请填写个税代扣代缴" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="办公用品赔偿" prop="yongpinpeichang">
          <el-input v-model="salaryForm.yongpinpeichang" placeholder="请填写办公用品赔偿" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="财务欠款" prop="caiwuqiankuan">
          <el-input v-model="salaryForm.caiwuqiankuan" placeholder="请填写财务欠款" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="违约违纪扣款" prop="weiyuekoukuan">
          <el-input v-model="salaryForm.weiyuekoukuan" placeholder="请填写违约违纪扣款" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="其他扣款" prop="qiankuan_qita">
          <el-input v-model="salaryForm.qiankuan_qita" placeholder="请填写其他扣款"></el-input>
        </el-form-item>
        <el-form-item label="实发金额" prop="shifaxinzi">
          <el-input v-model="salaryForm.shifaxinzi" placeholder="请填写实发金额" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="签订日期" prop="resignSignDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="salaryForm.resignSignDate"
                          type="date"
                          placeholder="请选择签订日期">
          </el-date-picker>
        </el-form-item>


        <el-form-item label-width="0" class="flex justify-around">
          <el-button class="submit-btn" type="primary" @click="submitForm('salaryForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="flex-sub  preview padding-lr">
      <div class="preview-info">
        <div class="preview-tips" v-if="!sys_offer_file">保存后可预览</div>
        <iframe v-else ref="iframe" frameborder="0" style="width: 100%;height: calc(100vh - 335px);"></iframe>
      </div>
      <div class="flex align-center justify-around margin-top">
        <el-button class="submit-btn margin-right" type="primary" @click="downOffer">下载离职薪酬核算单
        </el-button>
        <el-upload
            ref="upBtn"
            class="upload-demo margin-right"
            :action="actions"
            :data="{token}"
            :on-success="handleSuccess"
            :limit="1"
            :show-file-list="false"
        >
          <el-button class="submit-btn" type="warning">上传离职薪酬核算单反馈</el-button>
        </el-upload>

      </div>
      <div class="flex align-center justify-center margin-top" v-if="upload_file">
        <div>反馈附件：</div>
        <el-link v-if="upload_file" :href="upload_file" target="_blank" type="primary">
          下载离职薪酬核算单反馈附件
        </el-link>
      </div>
    </div>


  </div>
</template>

<script>

import rules from "@/utils/rules";
import {QAHOST, upFile} from "@/config";
import {getBaseDataByOfferId, getOfferHtml} from "@/api/common";
import {getPageName, zhDate} from "@/utils";
import {
  departStaffFullTimePay,
  departStaffFullTimePayReply,
  departStaffFullTimePayShow,
  departStaffFullTimeShow
} from "@/api/resignation";
import {downLoadOfferWord} from "@/api/entry";

export default {
  name: "offerList",
  data() {
    return {
      id: '',
      salaryForm: {
        xingming: '',
        ruzhiriqi: '',
        lizhiriqi: '',
        dangyueyingchuqin: '',
        dangyueshiichuqin: '',
        jibengongzi: '',
        kaoqinkoukuan: '',
        lizhiyingfa: '',
        weiyongnianjiahetiaoxiu: '',
        sickLeaveDays: '',
        xinzi_qita: '',
        gongjijindaikou: '',
        geshuidaikou: '',
        yongpinpeichang: '',
        caiwuqiankuan: '',
        qiankuan_qita: '',
        weiyuekoukuan: '',
        shifaxinzi: '',
        resignSignDate: '',

      },
      rules: {
        xingming: rules.mustInput,
        ruzhiriqi: rules.mustDate,
        lizhiriqi: rules.mustDate,
        dangyueyingchuqin: rules.mustInput,
        dangyueshiichuqin: rules.mustInput,
        jibengongzi: rules.mustInput,
        kaoqinkoukuan: rules.mustInput,
        lizhiyingfa: rules.mustInput,
        weiyongnianjiahetiaoxiu: rules.mustInput,
        sickLeaveDays: rules.mustInput,
        gongjijindaikou: rules.mustInput,
        geshuidaikou: rules.mustInput,
        yongpinpeichang: rules.mustInput,
        caiwuqiankuan: rules.mustInput,
        weiyuekoukuan: rules.mustInput,
        shifaxinzi: rules.mustInput,
        resignSignDate: rules.mustDate,
      },
      isSubmit: true,
      sys_offer_file: '',
      upload_file: '',
      iframe: '',
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    }
  },
  created() {
    this.$emit("setIndex", '/admin/resignationList', "全职员工离职薪酬核算单", true);
    this.id = Number(this.$route.params.id)
    this.departStaffFullTimePayShow()
  },
  components: {},
  methods: {
    async departStaffFullTimePayShow() {
      const res = await departStaffFullTimePayShow(this.id)
      if (res.data) {
        this.salaryForm = {
          xingming: res.data.xingming,
          ruzhiriqi: new Date(res.data.ruzhiriqi),
          lizhiriqi: new Date(res.data.lizhiriqi),
          dangyueyingchuqin: res.data.dangyueyingchuqin,
          dangyueshiichuqin: res.data.dangyueshiichuqin,
          jibengongzi: res.data.jibengongzi,
          kaoqinkoukuan: res.data.kaoqinkoukuan,
          lizhiyingfa: res.data.lizhiyingfa,
          weiyongnianjiahetiaoxiu: res.data.weiyongnianjiahetiaoxiu,
          sickLeaveDays: res.data.sickLeaveDays,
          xinzi_qita: res.data.xinzi_qita,
          gongjijindaikou: res.data.gongjijindaikou,
          geshuidaikou: res.data.geshuidaikou,
          yongpinpeichang: res.data.yongpinpeichang,
          caiwuqiankuan: res.data.caiwuqiankuan,
          qiankuan_qita: res.data.qiankuan_qita,
          weiyuekoukuan: res.data.weiyuekoukuan,
          shifaxinzi: res.data.shifaxinzi,
          resignSignDate: new Date(res.data.resignSignDate),
        }
        this.sys_offer_file = res.data.sys_offer_file
        this.upload_file = res.data.upload_file ? QAHOST + '/' + res.data.upload_file : ''
        if (res.data.sys_offer_file) {
          const iframe = await getOfferHtml(getPageName(res.data.sys_offer_file))
          this.iframe = QAHOST + '/' + iframe.data + '?v=' + new Date().getTime()
          this.$refs.iframe.contentWindow.location.replace(this.iframe)
        }
      } else {
        const info = await getBaseDataByOfferId(this.id)
        const info2 = await departStaffFullTimeShow(this.id)
        this.salaryForm.xingming = info.data.staff.xingming
        this.salaryForm.ruzhiriqi = new Date(info.data.staff.ruzhiriqi)
        this.salaryForm.jibengongzi = info.data.full_time_staff.jibenyuexin

        this.salaryForm.lizhiriqi = new Date(info2.data.lizhiriqi)
        this.salaryForm.dangyueyingchuqin = info2.data.yinggongzuotianshu
        this.salaryForm.dangyueshiichuqin = info2.data.shijigongzuotianshu



      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await departStaffFullTimePay(
        this.id,
        this.salaryForm.xingming,
        zhDate(new Date(this.salaryForm.ruzhiriqi)),
        zhDate(new Date(this.salaryForm.lizhiriqi)),
        this.salaryForm.dangyueyingchuqin,
        this.salaryForm.dangyueshiichuqin,
        this.salaryForm.jibengongzi,
        this.salaryForm.kaoqinkoukuan,
        this.salaryForm.lizhiyingfa,
        this.salaryForm.weiyongnianjiahetiaoxiu,
        this.salaryForm.sickLeaveDays,
        this.salaryForm.xinzi_qita,
        this.salaryForm.gongjijindaikou,
        this.salaryForm.geshuidaikou,
        this.salaryForm.yongpinpeichang,
        this.salaryForm.caiwuqiankuan,
        this.salaryForm.qiankuan_qita,
        this.salaryForm.weiyuekoukuan,
        this.salaryForm.shifaxinzi,
          zhDate(new Date(this.salaryForm.resignSignDate)),
      )

      if (_state.status === 200) {
        this.$message.success(_state.info)
        await this.departStaffFullTimePayShow()
      } else {
        this.$message.error(_state.info)
      }
    },
    //  下载附件
    async downOffer() {
      if (!this.iframe) return this.$message.error('请编辑保存后生成')
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let _data = await downLoadOfferWord(this.id, this.sys_offer_file)
      loading.close();
      let blob = _data.data
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.style.display = 'none';
      downloadElement.href = href;
      downloadElement.setAttribute('download', this.salaryForm.xingming + '离职薪酬核算单.docx');
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    },
    handleSuccess(file) {
      if (file.status === 200) {
        this.upFile(file.data)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    async upFile(url) {
      await departStaffFullTimePayReply(this.id, url)
      this.$message.success('上传成功')
      await this.departStaffFullTimePayShow()
    },
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.preview {
  height: 100%;

  .preview-title {
    text-align: center;
    line-height: 60px;
    font-size: 22px;
  }

  .preview-info {
    width: 100%;
    height: calc(100vh - 330px);
    overflow-y: auto;
    padding: 0 30px;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
  }
}
</style>
